// CIRCLES
.circle {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    
    &.large {
         height: 119px;
         width: 119px;
         font-size: 57px;
         position: absolute;
         z-index: -1;
         left: -90px;
         bottom: 45px;

         @include for-iphone678 {
            height: 130px;
            width: 130px;
         }
     }
     
     &.xl-desktop {
         display: none;
         height: 232px;
         width: 232px;
         font-size: 110px;
         position: absolute;
         right: 35%;
         bottom: 0;
  
         @include for-tablet-portrait-up {
             display: flex;
         }
     }
 
 
    &.medium {
        height: 66px;
        width: 66px;
        font-size: 16px;
        line-height: 19px;
 
    }
 
    &.small {
        height: 40px;
        min-width: 40px;
    }
 
    &.dark-blue {
        background-color: #1D445F;
    }
 
    &.red {
        background-color: #D01616;
    }

    &.burgundy {
        background-color: #872042;
    }

    &.turquoise {
        background-color: #326D96;
    }
 
    &.do-dont-circle {
        margin: auto;
        height: 95px;
        width: 95px;
        font-size: 20px;
    }
 
     // only on medium circle
    &.arrow {
         justify-content: unset;
         position: relative;
 
        &:before, &:after {
            content: '';
            border-bottom: 1.3px white solid;
            position: absolute;
            width: 9px;
            bottom: 20px;
         //  half the width of the circle
            left: 33px;
        }
 
        &:before {
            transform: rotate(135deg) translate(50%, 50%);
        }
 
        &:after {
             transform: rotate(45deg) translate(-50%, 50%); 
        }
    }
 
 }

 .dotted-red-background {
    display: block;
    background-image: url('../public/dotted-red-background.png');
    background-repeat: no-repeat;
    background-size: cover;

    &.left {
        position: absolute;
        left: -400px;
        top: 70%;
        transform: translateY(-50%);

        .contract & {
            display: none;
        }
    }

    &.right {
        position:absolute;
        right: -400px;
        top: 70%;
        transform: translateY(-50%);

        .contract & {
            display: none;
        }
    }
 }

 .dotted-shape {
    position: absolute;
 }
 .dotted-light-blue-background {
    display: block;
    background-image: url('../public/dotted-light-blue-background.png');
    background-repeat: no-repeat;
    background-size: contain;
 }

 .dotted-black-background {
    display: block;
    background-image: url('../public/dotted-black-background.png');
    background-repeat: no-repeat;
    background-size: contain;
 }

 .dotted-light-blue-background {
    display: block;
    background-image: url('../public/dotted-light-blue-background.png');
    background-repeat: no-repeat;
    background-size: contain;
 }
 
 // hide circle on 'Us' in first section
 section.part-1 {
     .part-a {
         .large {
             @include for-tablet-portrait-up {
                 display: none;
             }
         }
     }
 }


// RECTANGLES
.rectangle-item {
    position: relative;

    @include for-tablet-portrait-up {
        position: static;
    }
}

.rectangle {
    position: absolute;

    &.pink {
        background: #DDA5CC;
    }

    &.blue {
        background: #1D445F;
    }

    &.light-blue {
        background: #E2EBFF;
    }

    &.cream {
       background: #FCFCFC;

    }

    &.mobile {
        @include for-tablet-portrait-up {
            display: none;
        }
    }

    &.desktop {
        @include for-phone-only {
            display: none;
        }

        @include for-landscape-mobile {
            display: none;
        }
    }
}

// big shapes (rectangles) outside viewport on desktop
.desktop-bg-shapes {
    display: none;

    @include for-tablet-portrait-up {
        display: block;
        position: absolute;
        overflow: hidden;
        z-index: -3;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;

        .dotted-red-background {
            width: 800px;
            height: 800px;
        }

        &.start-agree {
            .dotted-light-blue-background, .dotted-black-background, .dotted-red-background.contract {
                display: none;
            }
        }

        &.contract {
            .ie11 {
                position: absolute;
            }

            .dotted-red-background {
                width: 1500px;
                height: 900px;
                transform: matrix(0.65, 0.8, -0.72, 0.65, 0, 0);

                &.ie11 {
                    top: 297px;
                    left: -750px;
                }
            }

            .dotted-light-blue-background {
                width: 618.83px;
                height: 624.77px;
                transform: matrix(-0.76, 0.69, -0.61, -0.76, 0, 0);

                &.one {
                    &.ie11 {
                        right: -549px;
                        top: 1349px;
                    }
                }

                &.two {
                    &.ie11 {
                        left: 309px; 
                        top: 5700px;
                    }
                }
            }

            .dotted-black-background {
                width: 1152.85px;
                height: 1113.26px;
                transform: matrix(0.66, 0.79, -0.71, 0.67, 0, 0);

                &.one {
                    &.ie11 {
                        left: 550px;
                        top: 1900px;
                    }
                }

                &.two {
                    &.ie11 {
                        right: 550px;
                        top: 3400px;
                    }
                }
            }
        }
    }
}

// rectangles start from right to left in each section (i.e rectangle-1 is either the furthest left rectangle/the only rectangle in the section)
.part-1, .sign {
    .rectangle-1 {
        transform: rotate(-43deg);
        z-index: -3;
    }
}

// 1. start
.start {
    .mobile {
        &.rectangle-1 {
            bottom: -638px;
            left: -85px;
            z-index: -1;
            width: 784.46px;
            height: 275.07px;
            transform: rotate(-42.74deg);
        }
    }

    .desktop {
        &.rectangle-1 {
            width: 590.94px;
            height: 372.49px;
            transform: rotate(135deg);
            z-index: -5;
            bottom: -132px;
            right: -143px;
        }
    }

}

// triangle should be the same size, colour and position on mobile in fill info 1 (part-1) and signature section
.part-1, .sign {
    .mobile {
        &.rectangle {
            width: 1167.68px;
            height: 596.85px;
            top: 111px;
            left: -396px;
        }
    }

    .desktop {
        &.background {
            width: 829px;
            height: 450px;
            z-index: -1;
            top: -10px;
            left: -45px;
            transform: rotate(-180deg);

            @include for-tablet-landscape-up {   
                left: -65px;
            }  
        }
    }
}

// fill info 2
.part-2 {
    background: #DDA5CC;
    z-index: 4;

    @include for-tablet-portrait-up {
        background: white;
        position: static;
        z-index: -1;
    }

    .mobile {
        &.rectangle-1 {
            width: 430.65px;
            height: 619.9px;
            background: #FCFCFC;
            transform: rotate(-42.74deg);
            right: -268px;
            top: -109px;
            z-index: -1;
        }
    }

    .desktop {
        &.rectangle-1 {
            width: 487px;
            height: 103px;
            transform: rotate(-180deg);
            top: -18px;
            left: -20px;
            z-index: -1;
        }

        &.rectangle-2 {
            width: 450.37px;
            height: 432.91px;
            transform: rotate(-155.17deg);
            right: -340px;
            top: 0px;

            @include for-tablet-landscape-up {
                top: 72px;
            }
        }

        &.dotted-black-background {
            display: none;

            @include for-tablet-portrait-up {
                height: 450px;
                width: 450px;
                transform: matrix(-0.91, -0.43, 0.41, -0.91, 0, 0);
                position: absolute;
                right: -283px;
                top: 107px;
                display: block;
            }

            @include for-tablet-landscape-up {
                top: 153px;
            }
        }
    }
}

// read carefully
.one {
    .mobile {
        &.rectangle-1 {
            height: 110px;
            width: calc(100% + 40px);
            top: 0;
            left: -20px;

            @include for-iphone678 {
                height: 120px;
            }
        }
    }

    .desktop {
        &.rectangle-1 {
            width: 1340.59px;
            height: 448.15px;
            transform: rotate(149.67deg);
            z-index: -1;
            left: -208px;
            top: 380px;
        }
    }
}

.two {
    .mobile {
        &.rectangle-1 {
            width: 313.23px;
            height: 313.07px;
            transform: rotate(29.6deg);
            left: -208px;
            top: 156px;

            @include for-iphone678 {
                width: 402.23px;
                height: 636.07px;
                transform: rotate(29.6deg);
                left: -204px;
                top: 95px;
            }
        }
    }
}

// for sections three and four
.mobile {
    &.three-four {
        &.rectangle-1 {
            width: 313.23px;
            height: 507.58px;
            top: -237px;
            left: -293px;
            transform: rotate(46.27deg);
            
            &.three {
                z-index: -1;
            }

            &.four {
                z-index: -1;
            }
        }
    
        &.rectangle-2 {
            width: 669.52px;
            height: 309.44px;
            transform: rotate(-43.81deg);
            bottom: -822px;
            right: -251px;

            &.three {
                z-index: -1;
            }

            &.four {
                z-index: 0;
            }
        }
    }
}


.four {
    background: #DDA5CC;
    z-index: 4;

    @include for-tablet-portrait-up {
        background: white;
        position: static;
    }
}

.five {
    .mobile {
        &.rectangle-1 {
            left: -205px; 
            width: 349.86px;
            height: 125.08px;
            transform: matrix(0.74, -0.7, 0.66, 0.73, 0, 0);
        }
    }
}

.six {
    .mobile {
        &.rectangle-1 {
            width: 669.52px;
            height: 434.44px;
            bottom: -787px;
            right: -264px;
            transform: rotate(-43.81deg);
        }
    }
}

.seven {
    .mobile {
        &.rectangle-1 {
            width: 651.81px;
            height: 228.56px;
            transform: matrix(0.74, -0.7, 0.66, 0.73, 0, 0);
            left: -348px;
        }
    }

    .desktop {
        &.circle.pink {
            display: none;

            @include for-tablet-portrait-up {
                z-index: -1;
                background: #DDA6CC;
                width: 640px;
                height: 640px;
                transform: matrix(0.65, 0.77, 0.75, -0.65, 0, 0);
                border-radius: 50%;
                position: absolute;
                display: block;
                left: -391px;
                top: 142px;
            }
        }

    }
    .circle.dark-blue {
        width: 67px;
        height: 67px;
        font-size: 35px;
        top: 27px;
        left: 19px;

        @include for-tablet-portrait-up {
            width: 96px;
            height: 96px;
            font-size: 50px;
            top: 50%;
            left: 340px;
            transform: translate(-50%, -50%);

        }
    }
}

.eight {
    .mobile {
        &.rectangle-1 {
            width: 784.81px;
            height: 213.56px;
            transform: matrix(0.74, -0.7, 0.66, 0.73, 0, 0);
            left: -210px;
            top: 249px;
        }
    }
}

.sign {
    .desktop {
        &.rectangle {
            &.background {
                height: 329px;
                width: 829px;
                z-index: -3;
                top: -165px;
                left: -45px;

                @include for-tablet-landscape-up {   
                    left: -65px;
                }  
            }
            &.rectangle-1 {
                width: 443.99px;
                height: 415.75px;
                background: #1D445F;
                transform: matrix(0.92, -0.39, -0.39, -0.92, 0, 0);
                bottom: -315px;
                right: -101px;
            }
        }
    }
}

// last page (agreement page)
.agreement-section {
    .mobile {
        &.rectangle-1 {
            width: 349.86px;
            height: 125.08px;
            transform: matrix(0.74, -0.7, 0.66, 0.73, 0, 0);
            top: 0;
            left: -187px;
        }
    }

    .desktop {
        &.rectangle-1 {
            width: 443.99px;
            height: 415.75px;
            transform: rotate(-156.89deg);
            bottom: -1131px;
            left: -102px;
            z-index: -4;
        }
    }
}

// for all rectangles
.rectangle {
    &.mobile {
        z-index: -3;
        @include for-tablet-portrait-up {
            display: none;
        }
    }
    
    &.desktop {
        display: none;
        @include for-tablet-portrait-up {
            display: block
        }
    }
}