@import "../config/mixins";
@import "../config/fonts";

// FORM ITEMS
textarea,
input,
select {
  width: inherit;
  box-sizing: border-box;
  background: #f1f5ff;
  border-radius: 6px 6px 0px 0px;
  border: none !important;
  border-bottom: solid 1px black !important;
  font-weight: lighter;
  padding: 10px 7px 0 7px;
  font: 400 10px $form_font_style;

  @include for-iphone678 {
    font-size: 11px;
  }

  @include for-tablet-portrait-up {
    font-size: 10px;
  }

  &:before {
    font-family: $header_font_style;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// remove spinner on number on firefox
input[type="number"] {
  -moz-appearance: textfield;
}

// make bottom border and text blue on focus
textarea,
select,
input {
  color: rgba(0, 0, 0, 0.5);

  &:focus {
    color: black;
    border-bottom: solid 1px black !important;
    outline: none;
  }
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;

  &:disabled {
    opacity: 1 !important;
  }
}

.select {
  position: relative;

  &:after {
    content: "";
    position: absolute;
    background-image: url("../public/triangle-dropdown-grey.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 7px;
    height: 9px;
    top: 50%;
    right: 5px;
  }
}

label {
  font-weight: bold;
  font-size: 12px;
}

// make bottom border and text red and show an icon on error
.form-item.error,
.icon-wrapper.error,
.error-input-wrap.error {
  input,
  textarea,
  select {
    color: #ae0726;
    border-bottom: solid 2px #ae0726 !important;

    &::placeholder {
      color: #ae0726;
    }
  }
}

.form-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 7px;
  width: 100%;

  input,
  select {
    height: 32px;
    width: 180px;

    @include for-iphone678 {
      width: 100%;
    }

    @include for-tablet-portrait-up {
      height: 32px;
      width: 204px;
    }
  }

  textarea {
    height: 68px;
    width: 180px;

    @include for-iphone678 {
      width: 100%;
    }

    @include for-tablet-portrait-up {
      width: 204px;
    }
  }

  // position x position of dropdown icon. Adjust background-size
  select {
    background-size: 4%;
    background-position-x: 95%;

    @include for-tablet-portrait-up {
      background-size: 9px;
    }
  }

  &.remove-margin {
    margin-bottom: 0 !important;
  }
}

.form-item.error,
.error-input-wrap.error {
  position: relative;

  &:after {
    content: "";
    background-image: url("../public/info-icon-error.png");
    background-size: cover;
    position: absolute;
    width: 10px;
    height: 10px;
    right: 6px;
    top: 13px;
  }

  &.textarea {
    &:after {
      top: 5px;
    }
  }
}

// doesn't allow resizing of textarea (by dragging bottom right corner of textarea)
textarea {
  resize: none;
}

// DATE
input[type="date" i] {
  font: 400 9.5px $body_font_style;
}
.date-input {
  .react-datepicker__input-container {
    display: none;

    @include for-tablet-portrait-up {
      display: inline-block;
    }

    input[type="text"] {
      height: 36px;
      width: 114px;
      background-image: url("../public/calendar.png");
      background-size: 11%;
      background-position: 90% 65%;
      background-repeat: no-repeat;
      font-size: 10px;

      @include for-iphone678 {
        font-size: 11px;
      }

      @include for-tablet-portrait-up {
        font-size: 10px;
        height: 38px;
      }
    }
  }

  &.form-item.resized-mobile .react-datepicker__input-container {
    display: inline-block;
  }
}

// info icon in form input and displaying message
.form-item {
  z-index: 0;

  .icon-wrapper {
    position: relative;
    z-index: 0;
    width: 100%;

    img {
      position: absolute;
      right: 6px;
      height: 10px;
      width: 10px;
      bottom: 7px;
      z-index: 10;

      &:hover {
        cursor: pointer;
      }

      &.textarea-icon {
        bottom: 0px;
        top: 12px;
      }
    }

    input,
    textarea {
      z-index: 0;
      padding: 7px 21px 0 7px;

      @include for-tablet-portrait-up {
        padding-top: 10px;
      }
    }
  }

  .helper-text-container {
    overflow: hidden;
    max-height: 0px;
    transition: max-height 0.5s;
    background-color: white;
    padding: 0 5px;
    padding-right: 16px;
    position: relative;

    @include for-tablet-portrait-up {
      position: absolute;
      border-radius: 6px;
      z-index: 5;
      padding: 5px 10px;
      padding-right: 12px;
      background: #ffffff;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
      opacity: 0;
      transition: opacity 0s, max-height 0s;
      margin-top: 0;

      &:after {
        content: "";
        background-image: url("../public/triangle-white-icon.png");
        background-repeat: no-repeat;
        background-size: cover;
        width: 8px;
        height: 7px;
        position: absolute;
        right: 8px;
        bottom: -6px;
      }
    }

    // .close {
    //     position: absolute;
    //     height: 10px;
    //     width: 10px;
    //     right: 2px;
    //     z-index: 1;
    //     top: 5px;
    //     cursor: pointer;

    //     @include for-tablet-portrait-up {
    //         top: 6px;
    //     }

    //     &:before, &:after {
    //         content: '';
    //         cursor: pointer;
    //         z-index: 10;
    //         display: block;
    //         height: 2px;
    //         width: 10px;
    //         position: absolute;
    //         top: 50%;
    //         left: 50%;
    //         margin-left: -8px;
    //         margin-top: -1px;
    //         background-color: white;
    //     }

    //     &:before {
    //         -webkit-transform: rotate(-45deg);
    //         -moz-transform: rotate(-45deg);
    //         -ms-transform: rotate(-45deg);
    //         transform: rotate(-45deg);
    //     }

    //     &:after {
    //         -webkit-transform: rotate(45deg);
    //         -moz-transform: rotate(45deg);
    //         -ms-transform: rotate(45deg);
    //         transform: rotate(45deg);
    //     }
    // }

    .helper-text {
      font-size: 9px;
      line-height: 11px;
      letter-spacing: -3%;
      color: #b8b8b8;

      margin: 6px 0;

      @include for-tablet-portrait-up {
        padding: 0;
        margin: 0;
        letter-spacing: -0.03em;
      }
    }
  }

  &.active {
    .helper-text-container {
      transition: max-height 0.5s;
      // margin-bottom: 3px;
      background-color: white;

      @include for-tablet-portrait-up {
        max-height: 70px;
        opacity: 1;
        transition: max-height, opacity 0.5s;
        overflow: visible;
        margin-bottom: unset;
      }
    }
  }

  &.comp-type {
    img {
      @include for-tablet-portrait-up {
        bottom: 50%;
        transform: translate(0, 50%);
        right: 4px;
      }
    }

    .helper-text-container {
      @include for-tablet-portrait-up {
        padding: 5px 10px 10px 10px;
        max-height: unset;
        width: 207px;
        left: -51px;
        top: 99px;
      }

      &:after {
        @include for-tablet-portrait-up {
          right: 10px;
        }
      }
    }

    &.active {
      .helper-text-container {
        max-height: 142px;

        @include for-tablet-portrait-up {
          left: -85px;
          top: -67px;
        }

        @include for-tablet-landscape-up {
          left: 115px;
        }

        &:after {
          @include for-tablet-landscape-up {
            left: 75px;
          }
        }
      }
    }
  }

  &.address {
    .icon-wrapper {
      img.textarea-icon {
        top: 8px;
      }
    }

    .helper-text-container {
      @include for-tablet-portrait-up {
        width: 181px;
        top: 100px;
        right: -92px;
        padding-left: 14px;
        padding-top: 10px;
        padding-right: 8px;
        padding-bottom: 6px;
      }

      &:after {
        @include for-tablet-portrait-up {
          right: 125px;
        }
      }
    }

    &.active {
      .helper-text-container {
        max-height: 58px;

        @include for-tablet-portrait-up {
          max-height: unset;
        }
      }
    }
  }

  &.the-project {
    .icon-wrapper {
      img {
        &.textarea-icon {
          @include for-tablet-portrait-up {
            top: 5px;
          }
        }
      }

      textarea {
        @include for-tablet-portrait-up {
          padding-top: 5px;
        }
      }
    }

    .helper-text-container {
      width: 180px;
      margin-left: 90px;
      margin-right: auto;

      @include for-iphone678 {
        width: calc(180px + 16vw);
      }

      @include for-iphone678plus {
        width: calc(180px + 20vw);
      }

      @include for-tablet-portrait-up {
        width: 214px;
        top: -68px;
        left: 436px;
        margin-left: 0;
        margin-right: 0;
      }

      &:after {
        @include for-tablet-portrait-up {
          left: 6px;
        }
      }

      .helper-text {
        text-shadow: 0.1px 0.1px #494949;

        @include for-tablet-portrait-up {
          text-shadow: none;
        }
      }
    }

    .icon-wrapper {
      width: auto;

      img.textarea-icon {
        top: 5px;
      }
    }

    &.active {
      .helper-text-container {
        max-height: 142px;

        @include for-tablet-portrait-up {
          max-height: unset;
        }
      }
    }
  }

  &.days-prior-notice {
    @include for-tablet-portrait-up {
      width: auto;
      margin: 0 23px;
      position: relative;
    }

    .icon-wrapper {
      img {
        left: 53px;
        bottom: 11.5px;
      }

      input {
        @include for-tablet-portrait-up {
          padding-right: 13px;
        }
      }
    }

    label {
      align-self: center;
    }

    .helper-text-container {
      width: 250px;
      margin-right: auto;

      @include for-tablet-portrait-up {
        width: 240px;
        margin: 0;
        top: -36px;
        left: -169px;
      }
    }

    .helper-text {
      @include for-tablet-portrait-up {
        width: auto;
      }
    }

    &.active {
      .helper-text-container {
        max-height: 100px;

        @include for-tablet-portrait-up {
          max-height: unset;
        }
      }
    }
  }

  &.info-text-area {
    @include for-tablet-portrait-up {
      position: relative;
    }
    .icon-wrapper {
      width: auto;

      @include for-tablet-portrait-up {
        margin-right: auto;
      }

      img.textarea-icon {
        top: 9px;
      }
    }

    .helper-text-container {
      width: 240px;

      @include for-tablet-portrait-up {
        top: -55px;
        left: 0;
        width: 300px;
      }

      &:after {
        @include for-tablet-portrait-up {
          right: 9px;
        }
      }
    }

    &.active {
      .helper-text-container {
        max-height: 80px;

        @include for-tablet-portrait-up {
          max-height: unset;
        }
      }
    }
  }

  &.main-contact {
    .helper-text-container {
      @include for-tablet-portrait-up {
        top: -41px;
        padding: 9px 5px 8px 13px;
        width: 204px;
      }

      &:after {
        @include for-tablet-portrait-up {
          right: 7px;
        }
      }
    }

    &.signature {
      .helper-text-container {
        @include for-tablet-portrait-up {
          padding: 9px 15px 5px 6px;
          width: 206px;
          top: -33px;
        }

        &:after {
          @include for-tablet-portrait-up {
            right: 9px;
          }
        }
      }
    }

    &.active {
      .helper-text-container {
        max-height: 109px;

        @include for-tablet-portrait-up {
          max-height: 150px;
        }
      }
    }
  }
}

input[type="date"] {
  min-width: 120px;
  // remove if doesn't change on apple
  -webkit-appearance: none;

  @include for-iphone678 {
    font-size: 11px;
    min-width: 125px;
  }

  &::-webkit-calendar-picker-indicator {
    background: url("../public/calendar.png");
    width: 12px;
    height: 12px;
    background-size: 80%;
    background-repeat: no-repeat;
  }
}

// for aligning form items that have an input and label inside. Labels are to the right (on mobile) and have a specific gap between them
.horizontal-align {
  max-width: 100%;
  display: flex;
  flex-direction: row;

  input {
    width: 75px;
  }

  label {
    margin: auto 0;
    margin-left: 20px;

    @include for-tablet-portrait-up {
      font-size: 12px;
      font-weight: bold;
    }
  }

  &.legal {
    @include for-tablet-portrait-up {
      display: flex;
      align-items: center;
      height: 50px;
      margin-bottom: 45px;
      flex-direction: row;
      justify-content: flex-start;
    }

    input,
    select {
      width: 100px;

      @include for-tablet-portrait-up {
        width: 88px;
        height: 32px;
      }
    }

    &.margin-bottom {
      margin-bottom: 15px;
    }
  }
}

// parties information container (where the two parties are either side by side on desktop or one above each other in mobile)
.part-1,
.four,
.sign {
  .inner {
    @include for-tablet-portrait-up {
      padding: 0 0 !important;
    }
  }
}
.parties-info-wrapper {
  z-index: 5;

  @include for-iphone678 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 27px;
  }

  // adds orange background behind form items on desktop
  @include for-tablet-portrait-up {
    display: flex;
    flex-direction: column;
    background-color: #dda5cc;
    align-items: unset;
    padding-right: unset;
  }

  .form-item {
    @include for-tablet-portrait-up {
      margin-bottom: 0px;
    }

    & + .form-item {
      @include for-tablet-portrait-up {
        margin-top: 11px;
      }

      &.nation {
        @include for-tablet-portrait-up {
          margin-top: 11px;
        }
      }
    }
  }

  .parties-info {
    display: flex;
    flex-direction: column;
    width: 273px;
    z-index: 2;
    position: relative;

    @include for-tablet-portrait-up {
      width: auto;
      flex-direction: row;
    }
  }
  // for all parties
  .party {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    background-color: #ffff;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    margin-bottom: 6.5vw;
    width: 232px;
    padding: 22px 26px;
    max-height: 1000px;

    @include for-iphone678 {
      width: 240px;
    }

    @include for-tablet-portrait-up {
      margin-left: 0;
      width: 256px;
    }

    // position text before ('SOPHiA GENETICS', 'you')
    &:before {
      font-family: $header_font_style;
      font-size: 20px;
      position: absolute;
      font-size: 14px;
      border-bottom: solid 1px black;
      color: black;

      @include for-iphone678 {
        font-size: 18px;
      }

      @include for-tablet-portrait-up {
        font-size: 14px;
        left: -40px;
      }

      @include for-tablet-landscape-up {
        font-size: 18px;
      }
    }

    .comp-type-nation {
      // margin-top: 11px;
      width: 100%;
    }

    @include for-tablet-portrait-up {
      .comp-type-nation {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 204px;
        z-index: 0;

        .comp-type {
          input {
            margin-bottom: 7px;
            padding-bottom: 5px;
          }
        }

        .nation {
          select {
            margin-left: auto;
            padding-bottom: 5px;
            background-position-y: 56%;
          }
        }
      }
    }
  }

  .part-a {
    position: relative;

    @include for-tablet-portrait-up {
      margin: 0 auto;
    }

    &:before {
      content: "SOPHiA GENETICS:";
      width: 72px;
      left: -46px;
      top: 9px;

      @include for-iphone678 {
        width: 93px;
        left: -65px;
        top: -3px;
      }

      @include for-tablet-portrait-up {
        width: 84px;
        left: -70px;
        top: 8px;
      }

      @include for-tablet-landscape-up {
        width: 104px;
        left: -90px;
        top: -2px;
      }
    }
  }

  .part-b {
    position: relative;

    @include for-phone-only {
      margin-bottom: 20px;
    }

    @include for-tablet-portrait-up {
      margin-right: auto;
    }

    &:before {
      content: "You:";
      left: -34px;
      top: 27px;
      width: 41px;

      @include for-iphone678 {
        top: 21px;
        left: -38px;
      }

      @include for-tablet-portrait-up {
        left: -31px;
        top: 28px;
        width: 46px;
      }

      @include for-tablet-landscape-up {
        left: -40px;
        top: 23px;
        width: 55px;
      }
    }
  }
}
