@import "config/fonts";
@import "config/mixins";
@import "components/index";

html {
  -webkit-text-size-adjust: 100%; /* Prevent font scaling in landscape while allowing user zoom */
}

// show landscape error on  mobile landscape
html {
  @include for-landscape-mobile {
    &.mobile-safari {
      background-color: #223b7c;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      body {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #223b7c;
        height: 100%;
        width: 100%;

        main {
          height: unset !important;

          .wrapper {
            display: none;
          }
        }
      }
    }
  }
  body {
    .landscape-error {
      display: none;

      @include for-landscape-mobile {
        display: block;
        position: absolute;
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;
        background-color: #223b7c;
        z-index: 999999999;

        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(270deg);
        }
      }
    }

    @include for-landscape-mobile {
      main {
        display: none;
      }
    }
  }
}

body {
  margin: 0;
  font-size: 1em;
}

main {
  @include for-tablet-portrait-up {
    padding-top: 56.3px;
  }
}

.viewport {
  position: relative;
}

* {
  box-sizing: border-box;
}

// removes blue square background on tap/highlight
input,
textarea,
button,
select,
a,
div {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

// SECTIONS
section {
  display: flex;
  flex-direction: column;
  padding: 14px 20px 80px;
  overflow: hidden;
  position: relative;

  @include for-tablet-portrait-up {
    padding: 0 45px;
  }

  @include for-tablet-landscape-up {
    padding: 0 65px;
    max-width: 829px;
    width: 829px;
    margin: 0 auto;
  }
}

.wrapper-container {
  @include for-tablet-portrait-up {
    padding: 0 45px;
  }

  @include for-tablet-landscape-up {
    padding: 0 58px;
  }
}

// FONT STYLES
p,
ol,
li,
span,
textarea,
select,
input {
  font-family: $body_font_style;
}

h1,
h2,
h3,
h4,
textarea:before,
select:before,
input:before,
.party:before,
label,
.circle > span,
.form-item > span,
a,
.confirm-send-container > p,
.fill-text span,
.rounded-edge-button,
.popup > span,
span.number,
p.small-print {
  font-family: $header_font_style;
}

// H1's
h1 {
  font-size: 30px;
  font-weight: normal;
  margin-block-start: 0;

  @include for-iphone678 {
    font-size: 30px;
  }

  @include for-tablet-portrait-up {
    font-size: 54px;
    font-weight: 500;
  }
}

// H2's
h2 {
  font-size: 20px;
  font-weight: normal;

  @include for-tablet-portrait-up {
    font-size: 36px;
    // this agreement starts on : and the parties are considering working together on : (for tablet + ). Our mutual commitment: protecting our respective Confidential Information.
    margin-top: unset;
    margin-block-start: 1.3em;
    margin-block-end: 1.3em;
  }
}

// ALL PARAGRAPHS
p {
  font-size: 14px;
  line-height: 28px;
  width: 100%;

  @include for-iphone678 {
    font-size: 16px;
  }
}

//  section 2, 3, 5
.text-blockend-m {
  p {
    margin-block-end: 2em;

    @include for-tablet-portrait-up {
      margin-block-end: 2em;
      margin-block-start: 2em;
    }

    &:not(:first-of-type) {
      @include for-tablet-portrait-up {
        margin-block-start: 2em;
      }
    }

    &:first-of-type {
      @include for-tablet-portrait-up {
        margin-block-start: 2.3em;
      }
    }
  }
}

// BLUE UNDERLINE
.blue-underline {
  color: inherit;
  background: rgba(255, 255, 255, 0);

  &.active {
    box-shadow: 0px 5px 0px 0px #e2eaff;
    transition: box-shadow 2s;
  }

  &.bold {
    font-weight: 700;
  }
}

.cross-list,
.tick-list {
  li {
    font-size: 14px;
    line-height: 20px;

    @include for-iphone678 {
      font-size: 16px;
    }

    @include for-tablet-portrait-up {
      font-size: 14px;
    }
  }
}

// for copyright
.copyright-wrapper {
  text-align: center;
  margin-top: 31px;

  @include for-tablet-portrait-up {
    position: absolute;
    bottom: 20px;
    left: 45px;
    text-align: center;
  }

  @include for-tablet-landscape-up {
    left: 65px;
  }

  p {
    font-weight: 600;
    color: #686868;
    font-family: "Abhaya Libre", serif;
    font-size: 10px;
    line-height: unset;
    margin: 0;
  }

  img {
    object-fit: contain;
    width: 70px;
    margin-top: 5px;
  }
}

// BULLETS
main {
  ul {
    line-height: 28px;
    list-style: none;
    margin: 0 0 auto auto;

    li {
      position: relative;
      margin: 0 5px 13px -15px;

      @include for-tablet-portrait-up {
        margin: 0 5px 20px -15px;
      }
    }
    // for ticks
    &.tick-list {
      position: relative;

      @include for-tablet-portrait-up {
        width: 43%;
      }

      li {
        // stars for tick list
        &:before {
          content: "";
          background: url("public/star-list.png");
          background-repeat: no-repeat;
          position: absolute;
          height: 20px;
          width: 20px;
          border-bottom: none;
          left: -30px;
          background-size: 100%;
          top: 2px;

          @include for-tablet-portrait-up {
            left: -24px;
            top: -1px;
          }
        }
      }
    }
    // list with no entry as bullets
    &.cross-list {
      position: relative;

      li {
        @include for-iphone678 {
          margin-bottom: 25px;
        }

        @include for-tablet-portrait-up {
          margin-bottom: 20px;
        }

        &:before {
          content: "";
          background: url("public/cross-list.png");
          background-repeat: no-repeat;
          position: absolute;
          height: 100%;
          width: 100%;
          border-bottom: none;
          left: -24px;
          top: 4px;
          background-size: 12px;
        }
      }
    }
  }
}

// split lists into two when tablet or above
.list-container {
  @include for-tablet-portrait-up {
    display: flex;
    justify-content: space-between;
  }
}

.start-agree-viewport {
  .swiper-wrapper {
    @include for-tablet-landscape-up {
      max-height: 110vh;
    }

    @include for-desktop-up {
      max-height: 100%;
    }
  }

  ul {
    @include for-tablet-portrait-up {
      margin-left: 24px;
    }

    &.tick-list {
      li:before {
        @include for-tablet-portrait-up {
          background-size: 15px;
          left: -28px;
          top: 5px;
        }
      }
    }
  }
}

// tick list for sections six and eight
.six,
.eight {
  ul.tick-list {
    @include for-tablet-portrait-up {
      width: 46%;
      margin: 0 0 auto 26px;
    }
  }
}

.six {
  ul.tick-list {
    li:before {
      @include for-tablet-portrait-up {
        left: -26px;
      }
    }
  }
}

// TITLES WITH ORANGE DOTS NEXT TO NUMBERS
.title-read-container {
  display: flex;
  margin-bottom: 3vw;
  width: 100%;

  // remove margin bottom for ipad and above to add margin to top and bottom of content instead
  @include for-tablet-portrait-up {
    margin-bottom: 0;
    height: auto;
    padding-top: unset;
  }

  .number {
    font-size: 30px;
    padding: auto;
    display: inline-flex;
    align-self: flex-end;
    position: relative;

    @include for-tablet-portrait-up {
      font-size: 30px;
      margin-left: 30px;
    }

    &:after {
      content: "";
      height: 7px;
      width: 7px;
      background-color: #eb2666;
      position: absolute;
      left: 30px;
    }
  }

  h3 {
    font-size: 14px;
    margin-left: 30px;
    font-weight: lighter;
    width: 100%;
    display: inline-block;
    align-self: flex-end;
    margin-block-start: 0;
    margin-block-end: 5.4px;
    z-index: 1;

    @include for-iphone678 {
      font-size: 16px;
    }

    @include for-tablet-portrait-up {
      font-size: 22px;
    }
  }
}

.six,
.seven {
  .title-read-container {
    @include for-tablet-portrait-up {
      margin-bottom: 19.6px;
    }
  }
}

// quote marks behind start and agreement headers on desktop
.quote-header {
  max-width: 280px;
  position: relative;

  @include for-tablet-portrait-up {
    max-width: 100%;
  }

  &.desktop {
    max-width: 100%;
  }
}

// READ CAREFULLY CONTENT desktop - add margin top and bottom
@include for-tablet-portrait-up {
  .one,
  .two,
  .three,
  .four,
  .five,
  .six,
  .seven,
  .eight,
  .nine {
    .content {
      margin-top: 2.3em;
      margin-bottom: 2.3em;
    }
  }

  .three {
    .content {
      margin-top: 3.2em;
    }
  }
  .six,
  .seven {
    .content {
      margin-bottom: 1em;
    }
  }
}

// SWIPER
.swiper-container {
  @include for-tablet-portrait-up {
    height: auto;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25);
    background-color: white;
  }

  @include for-tablet-landscape-up {
    width: 836px;
    max-width: 829px;
    margin: 0 auto;
  }

  .swiper-slide {
    min-height: calc(100vh - 44px);
    overflow-x: hidden;
    display: flex;

    &.hide {
      @include for-tablet-portrait-up {
        display: none;
      }
    }

    @include for-tablet-portrait-up {
      min-height: 100vh;
      flex-direction: column;
      overflow: visible;
    }

    @include for-tablet-landscape-up {
      width: 100%;
    }
  }

  &.swiper-container-autoheight {
    @include for-tablet-portrait-up {
      height: 100%;
    }

    .swiper-slide {
      @include for-tablet-portrait-up {
        height: 100%;
      }
    }
  }
}

.contract-viewport {
  .swiper-wrapper {
    @include for-tablet-portrait-up {
      height: 100% !important;
      flex-direction: column;
    }
  }

  .swiper-slide {
    @include for-tablet-portrait-up {
      margin-right: 0 !important;
      min-height: auto;
    }
  }

  section {
    @include for-tablet-portrait-up {
      padding: 0;
      overflow: visible;
    }
  }
}

// SECTIONS - on mobile these are the individual slides
// start section
.start {
  padding-top: 61px;
  position: relative;

  @include for-tablet-portrait-up {
    padding-top: 30px;
    // hide if inactive
    display: none;
  }

  @include for-tablet-landscape-up {
    padding-bottom: 125px;
  }

  &.active {
    @include for-tablet-portrait-up {
      // height is 813px minus the height of the header
      display: flex;
      align-items: center;
      height: 100%;
    }
  }

  span.dotted-red-background {
    width: 800px;
    height: 800px;
    position: absolute;
    bottom: -521px;
    right: -354px;

    @include for-iphone678 {
      bottom: -400px;
    }

    @include for-tablet-portrait-up {
      display: none;
    }
  }

  .quote-header {
    h1 {
      padding-top: 12px;
      display: inline-block;
      position: relative;

      @include for-tablet-portrait-up {
        padding-right: 238px;
        margin-bottom: 0;
      }

      &:after {
        content: "";
        background-image: url("public/Logo_SOPHiA-1.png");
        background-repeat: no-repeat;
        background-size: contain;
        display: block;
        width: 139px;
        height: 45px;
        position: absolute;
        top: -54px;

        @include for-tablet-portrait-up {
          width: 238px;
          height: 77px;
          top: 25px;
          right: -55px;
        }

        @include for-tablet-landscape-up {
          right: -77px;
        }
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;

    @include for-tablet-portrait-up {
      flex-grow: 1;
    }
  }

  .hello {
    display: inline-block;
    font-size: 22px;
    font-weight: 700;
    margin: 0px 0 -10px 0;

    @include for-iphone678 {
      font-size: 26px;
    }

    @include for-tablet-portrait-up {
      font-size: 36px;
      font-weight: 800;
      margin: 70px 0 16px 0;
    }
  }

  .subtitle {
    font-size: 11px;
    line-height: 20px;

    @include for-tablet-portrait-up {
      width: 634px;
      font-weight: 18px;
      line-height: 28px;
      margin-top: 0;
      font-size: 18px;
    }
  }

  p {
    @include for-tablet-portrait-up {
      font-size: 18px;
    }
  }

  .container {
    display: flex;
    flex-direction: column;

    @include for-tablet-portrait-up {
      order: 3;
      flex-grow: 1;
      width: 512px;
    }
  }

  .processes {
    display: flex;

    p {
      font-size: 6px;
      line-height: 8px;
      margin: 0;
      text-align: center;
      margin-top: 5px;
      overflow: hidden;
      white-space: nowrap;

      @include for-tablet-portrait-up {
        font-size: 11px;
        line-height: 16px;
      }
    }
  }

  .process {
    display: flex;
    flex-direction: column;
    position: relative;
    padding-right: 15px;

    @include for-iphone678 {
      padding-right: 20px;
    }

    @include for-tablet-portrait-up {
      padding-right: 26px;
    }

    &:before {
      content: "";
      background-size: contain;
      background-repeat: no-repeat;
      display: block;
      margin: 0 auto;
      margin-top: auto;
    }

    &:not(:last-of-type):after {
      content: "";
      background-image: url("public/process-arrow.png");
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      height: 10px;
      width: 10px;
      top: 44%;
      transform: translateY(-50%);
      right: 4px;

      @include for-tablet-portrait-up {
        height: 17px;
        width: 17px;
      }
    }
  }

  .process-1,
  .process-3 {
    &:before {
      background-image: url("public/1-3-process.png");
      width: 15px;
      height: 18px;

      @include for-iphone678 {
        width: 19.42px;
        height: 23.73px;
      }

      @include for-tablet-portrait-up {
        width: 31.09px;
        height: 38px;
      }
    }
  }

  .process-3.process {
    padding-right: 0;
    margin-right: 10px;

    @include for-tablet-portrait-up {
      margin-right: 13px;
    }

    &:after {
      right: -13px;

      @include for-tablet-portrait-up {
        right: -20px;
      }
    }
  }

  .process-2,
  .process-5,
  .process-7 {
    &:before {
      background-image: url("public/2-5-7-process.png");
      width: 18px;
      height: 11px;

      @include for-iphone678 {
        width: 21px;
        height: 14px;
      }

      @include for-tablet-portrait-up {
        height: 23.32px;
        width: 33.68px;
      }
    }
  }

  .process-4 {
    @include for-tablet-portrait-up {
      padding-right: 20px;
    }
    &:before {
      background-image: url("public/4-process.png");
      width: 16px;
      height: 19px;

      @include for-iphone678 {
        width: 21.04px;
        height: 24.27px;
      }

      @include for-tablet-portrait-up {
        height: 38.86px;
        width: 33.68px;
      }
    }
  }

  .process-6 {
    &:before {
      background-image: url("public/6-process.png");
      width: 18px;
      height: 6px;

      @include for-iphone678 {
        width: 21.01px;
        height: 6.54px;
      }

      @include for-tablet-portrait-up {
        height: 10.47px;
        width: 33.64px;
      }
    }

    p {
      margin-top: 8px;
    }
  }

  button.rounded-edge-button {
    @include for-tablet-portrait-up {
      margin-top: 69px;
      margin-left: auto;
    }
  }

  .agreement-container {
    display: flex;
    margin-top: 32px;

    @include for-tablet-portrait-up {
      order: 2;
      margin-bottom: 47px;
    }

    a {
      margin-left: 25px;

      @include for-tablet-portrait-up {
        margin-left: auto;
      }
    }
  }

  span.clock-circle {
    background-image: url("public/clock-icon-amurabi.png");
    background-repeat: no-repeat;
    background-size: 100%;
    height: 41px;
    width: 40px;

    @include for-tablet-portrait-up {
      height: 46px;
      width: 46px;
    }
  }

  .fill-text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 17px;
    width: 195px;

    @include for-tablet-portrait-up {
      width: 209px;
      padding-left: 15px;
    }

    span {
      font-size: 12px;

      @include for-tablet-portrait-up {
        font-size: 18px;
      }
    }
  }

  .small-print {
    font-size: 14px;
    line-height: 18px;
    margin-top: 28px;
    width: 191px;

    @include for-iphone678 {
      font-size: 15px;
    }

    @include for-tablet-landscape-up {
      line-height: 19px;
      margin-top: 2vw;
      width: 201px;
      font-size: 16px;
    }
  }
}

// SECTION 2.1 fill in information (this confidentiality agreement (this "Agreement") is made between :
.part-1 {
  .wrapper-container {
    @include for-tablet-portrait-up {
      padding-top: 110px;
      padding-left: 0;
      padding-right: 0;
    }
  }

  .tips-header {
    &:before {
      @include for-tablet-portrait-up {
        content: "";
        background-image: url("public/Logo_SOPHiA-1.png");
        background-repeat: no-repeat;
        background-size: contain;
        display: block;
        width: 216px;
        height: 70px;
        position: absolute;
        top: 30px;
      }
    }
  }

  .quote-header {
    @include for-tablet-portrait-up {
      padding-left: 58px;
    }
  }

  h2 {
    @include for-tablet-portrait-up {
      position: relative;
    }
  }

  .parties-info-wrapper {
    @include for-tablet-portrait-up {
      justify-content: flex-end;
      padding-top: 56px;
      padding-bottom: 23px;
      overflow-x: hidden;
    }

    p {
      @include for-tablet-portrait-up {
        margin: 0;
        margin-top: 10px;
      }
      &.agreement-and-affiliates {
        @include for-tablet-portrait-up {
          margin-top: 5px;
        }
      }
    }
  }

  .parties-info {
    @include for-tablet-portrait-up {
      position: relative;
    }

    .circle.large {
      z-index: -1;
      left: -62px;
      bottom: 345px;

      @include for-tablet-portrait-up {
        display: none;
      }
    }
  }

  .party {
    max-height: 488px;

    @include for-tablet-portrait-up {
      // height: 280px;
      margin-bottom: 59px;
    }
  }

  .form-item {
    margin-bottom: 11px;

    @include for-tablet-portrait-up {
      margin-bottom: 0px;
    }

    &.comp-name {
      margin-bottom: 11px;
    }

    &.comp-type {
      margin-bottom: 11px;

      @include for-tablet-portrait-up {
        margin-bottom: 0px;
      }

      &.active {
        @include for-tablet-portrait-up {
          margin-bottom: 0px;
          position: relative;
        }

        .party-b & {
          margin-bottom: 0;

          @include for-tablet-portrait-up {
            margin-bottom: 0px;
            position: relative;
          }
        }
      }
    }

    &.address {
      @include for-tablet-portrait-up {
        margin-bottom: 0;
        margin-top: 11px;
      }
    }
  }
  @include for-tablet-portrait-up {
    .icon-wrapper {
      margin-bottom: -6px;

      textarea {
        height: 75px;
      }
    }
  }

  p {
    @include for-tablet-portrait-up {
      padding-left: 45px;
      padding-right: 45px;
    }

    @include for-tablet-portrait-up {
      padding-left: 65px;
      padding-right: 65px;
    }
  }
}

// SECTION 2.2 - fill in information (the parties are considering working together on:)
.part-2 {
  display: flex;
  flex-direction: column;
  z-index: 1;

  &.active {
    position: relative;

    @include for-tablet-portrait-up {
      z-index: unset;
      overflow-x: hidden;
    }
  }

  h2 {
    position: relative;

    @include for-tablet-portrait-up {
      width: 620px;
      margin-bottom: 35px;
      margin-top: 45px;
    }
  }

  .agreement-start-header {
    @include for-tablet-portrait-up {
      margin-top: 35px;
      margin-bottom: 58px;
    }
  }

  .start-project-container {
    position: relative;

    @include for-tablet-portrait-up {
      width: 266px;
      width: auto;
      z-index: 1;
    }
  }
  .the-project {
    position: relative;
    margin: 0 auto;

    @include for-tablet-portrait-up {
      margin-right: auto;
      margin-left: unset;
      width: 590px;
    }

    .icon-wrapper {
      margin-right: auto;
      padding-left: 90px;

      @include for-tablet-portrait-up {
        margin: 0;
        margin-right: auto;
        margin-left: 26px;
        padding-left: 91px;
      }

      &:before {
        content: '"The Project"';
        font-size: 12px;
        position: absolute;
        left: 0px;
        top: 10px;
        width: 92px;
        padding-bottom: 7px;
        border-bottom: solid 0.5px #000000;
        color: #000000;
        z-index: 1;
        font-weight: 700;
        font-family: "OpenSans";

        @include for-tablet-portrait-up {
          top: 10px;
          width: 92px;
          left: 2px;
        }
      }

      textarea {
        width: 180px;
        flex-grow: 1;
        height: 103px;
        line-height: 18px;
        padding-top: 1px;

        @include for-iphone678 {
          width: calc(180px + 16vw);
        }

        @include for-iphone678plus {
          width: calc(180px + 20vw);
        }

        @include for-tablet-portrait-up {
          flex-grow: 0;
          width: 340px;
          height: 90px;
        }

        &::-webkit-input-placeholder {
          /* Edge */
          line-height: 20px;
        }

        &:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          line-height: 20px;
        }

        &::placeholder {
          line-height: 20px;
        }
      }
    }
  }
  // this agreement starts on :
  h2 {
    // this agreement starts on : and the parties are considering working together on : (for tablet + )
    @include for-tablet-landscape-up {
      margin-top: unset;
      margin-block-start: 1.66em;
      margin-block-end: 1.66em;
    }
  }

  .date-input {
    display: flex;
    position: relative;
    justify-content: flex-start;
    flex-direction: row;
    z-index: 2;

    @include for-tablet-portrait-up {
      margin-left: 143px;
      justify-content: unset;

      &.form-item {
        justify-content: flex-start;

        &.ie11 {
          justify-content: flex-start;
        }
      }
    }

    &:before {
      content: "Starting date:";
      position: absolute;
      z-index: 1000;
      font-size: 8px;
      left: 8px;
      top: 3px;
      color: #eb2666;
      font-family: "OpenSans";

      @include for-tablet-portrait-up {
        display: none;
      }
    }

    .starting-date-label {
      display: none;

      @include for-tablet-portrait-up {
        display: block;
        position: absolute;
        z-index: 2;
        font-size: 8px;
        left: 8px;
        top: 3px;
        color: #eb2666;
        font-family: "OpenSans";
      }
    }

    // xxx For xxx
    label {
      font-size: 14px;
      margin: auto 25px auto 25px;

      @include for-iphone678 {
        font-size: 16px;
      }

      @include for-tablet-portrait-up {
        font-size: 16px;
      }
    }

    // give a height to date input and select
    input[type="date" i],
    select {
      height: 36px;
    }

    input[type="date"] {
      width: 114px;

      @include for-iphone678 {
        width: calc(140px + 10vw);
      }

      @include for-tablet-portrait-up {
        width: 140px;
      }
    }

    .mobile {
      @include for-tablet-portrait-up {
        display: none;
      }
    }

    .desktop {
      display: none;

      @include for-tablet-portrait-up {
        display: block;
      }
    }

    // position dropdown icon
    select {
      background-size: 10%;
      background-position-x: 87%;
      background-position-y: 50%;

      @include for-iphone678plus {
        width: calc(80px + 7vw);
      }

      @include for-tablet-portrait-up {
        height: 40px;
        width: 80px;
      }
    }
  }

  .three-years {
    margin-left: 23px;

    @include for-tablet-portrait-up {
      margin-left: 69px;
    }
  }

  .form-date-wrapper {
    position: relative;

    @include for-tablet-portrait-up {
      left: -46px;
    }
  }

  .end-date-project-container {
    display: flex;
    position: absolute;
    bottom: -56px;
    left: 35px;
    width: 190px;

    @include for-iphone678 {
      position: absolute;
      left: 51px;
    }

    @include for-tablet-portrait-up {
      position: absolute;
      bottom: -51px;
      left: 57px;
    }
    .circle {
      margin-right: auto;

      @include for-tablet-portrait-up {
        margin-right: auto;
      }
    }

    .end-text {
      position: absolute;
      color: white;
      top: 17px;
      left: 50%;
      transform: translate(-50%);
    }

    h2 {
      font-size: 24px;
      position: absolute;
      right: -20px;
      display: block;
      width: auto;
      top: 21px;

      @include for-tablet-portrait-up {
        top: 28px;
      }
    }
  }

  h2.date-text {
    margin: 0;
    align-self: center;
    font-size: 22px;
  }

  .termination-date {
    margin-top: 78px;

    @include for-tablet-portrait-up {
      margin-top: 60px;
      display: flex;
      z-index: 1;
    }

    p {
      @include for-tablet-portrait-up {
        width: 319px;
      }
    }
  }

  input.termination-date-input {
    height: 36px;
    width: 70px;
    min-height: 36px;
    min-width: 70px;

    @include for-tablet-portrait-up {
      padding-bottom: 3px;
      height: 40px;
    }
  }

  .form-item {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;

    @include for-tablet-portrait-up {
      flex-wrap: nowrap;
    }
  }

  .icon-wrapper {
    align-items: center;
    flex-direction: row;
    justify-content: unset;
    width: auto;

    @include for-tablet-portrait-up {
      width: auto;
      z-index: 1;
      justify-content: center;
    }

    input {
      margin-right: 22px;
    }
  }

  label {
    white-space: nowrap;
    align-self: flex-end;

    @include for-tablet-portrait-up {
      white-space: normal;
      align-self: auto;
    }
  }

  select {
    width: 80px;
  }
}

// SECTION 3.1 - confidential-information
.one {
  position: relative;
  padding-top: 0;

  h2 {
    @include for-tablet-portrait-up {
      margin-block-start: 15px;
    }
  }

  .title-read-container {
    padding-top: 0;
    margin-top: 36px;

    @include for-tablet-portrait-up {
      margin-top: 24px;
    }
  }

  .content {
    p:first-child {
      @include for-tablet-portrait-up {
        margin-bottom: 30px;
      }
    }

    p:nth-child(2) {
      &:not(.helper-text) {
        @include for-tablet-portrait-up {
          margin-bottom: -5px;
        }
      }
    }
  }

  .information-container {
    display: flex;
    flex-direction: column;

    @include for-tablet-portrait-up {
      flex-direction: row;
    }

    .form-item {
      &.info-text-area {
        display: flex;
        flex-direction: column;

        &.desktop {
          display: none;

          @include for-tablet-portrait-up {
            margin-right: 25px;
            width: 308px;
            display: flex;
          }
        }

        &.mobile {
          @include for-tablet-portrait-up {
            display: none;
          }
        }

        textarea {
          margin: auto;
          width: 245px;
          height: 68px;
          padding-top: 4px;

          @include for-tablet-portrait-up {
            width: 300px;
            height: 100px;
            padding-top: 4px;
          }

          &::-webkit-input-placeholder {
            /* Edge */
            line-height: 25px;
          }

          &:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            line-height: 25px;
          }

          &::placeholder {
            line-height: 25px;
          }

          &:focus {
            &::-webkit-input-placeholder {
              /* Edge */
              color: transparent;
            }

            &:-ms-input-placeholder {
              /* Internet Explorer 10-11 */
              color: transparent;
            }

            &::placeholder {
              color: transparent;
            }
          }
        }
      }
    }

    .orange-bg {
      margin: 13px auto;
      background-color: rgba(255, 232, 220, 0.7);
      border-radius: 7px;
      width: 281px;
      height: 103;
      display: flex;
      justify-content: center;
      padding: 10px 13px;

      @include for-iphone678 {
        width: 300px;
      }

      @include for-tablet-portrait-up {
        background-color: transparent;
        margin-left: 0;
        width: 305px;
      }

      p {
        font-size: 12px;
        line-height: 20px;
        margin-block-start: 0;
        margin-block-end: 0;
        margin-inline-start: 0px;
        margin-inline-end: 0px;

        @include for-iphone678 {
          font-size: 14px;
        }

        @include for-tablet-portrait-up {
          margin-block-end: 0;
          line-height: 25px;
        }
      }
    }
  }
}

// SECTION 3.3 - do's and dont's
.three {
  .do-dont-container {
    display: flex;
    flex-direction: column;
    width: 100%;

    @include for-tablet-portrait-up {
      flex-direction: row;
      justify-content: space-between;
    }

    .do,
    .dont {
      margin-top: 3vw;

      @include for-tablet-portrait-up {
        width: 45%;
        margin-top: unset;
      }
    }

    @include for-tablet-portrait-up {
      .text-blockend-m {
        p:first-of-type {
          margin-block-start: 1em;
        }

        p:last-of-type {
          margin-block-end: 0;
        }
      }
    }
  }
}

// SECTION 3.4 - how will the parties communicate confidential information
.four {
  @include for-tablet-portrait-up {
    margin-bottom: 40px;
  }

  .wrapper-container {
    @include for-tablet-portrait-up {
      padding: 0;
    }
  }

  .title-read-container,
  p.sub-title {
    @include for-tablet-portrait-up {
      padding-left: 65px;
    }
  }

  .content {
    @include for-tablet-portrait-up {
      margin-top: 30px;
    }
  }

  p.sub-title {
    @include for-tablet-portrait-up {
      width: 641px;
      margin-top: 0;
    }
  }

  .parties-info-wrapper {
    @include for-tablet-portrait-up {
      justify-content: center;
      height: 169px;
    }

    .parties-info {
      @include for-tablet-portrait-up {
        padding-left: 45px;
      }

      @include for-tablet-portrait-up {
        padding-left: 65px;
      }

      .party {
        &.part-a {
          @include for-tablet-portrait-up {
            margin: auto;
          }
        }

        &.part-b {
          @include for-tablet-portrait-up {
            margin: auto;
          }
        }
      }

      .circle.large {
        left: -76px;
        bottom: -35px;
        height: 103px;
        width: 103px;

        @include for-tablet-portrait-up {
          height: 137px;
          width: 137px;
          bottom: -61px;
          left: 233px;

          span {
            font-size: 76px;
          }
        }

        @include for-tablet-landscape-up {
          left: 251px;
          bottom: -67px;
        }
      }
    }
  }
}

// SECTION 3.7 - how will a dispute between the parties be solved
.seven {
  p:first-child {
    @include for-tablet-portrait-up {
      margin-bottom: 0;
    }
  }

  .wrapper-container {
    @include for-tablet-portrait-up {
      padding-right: 29px;
    }
  }

  input {
    height: 36px;
    width: 70px;

    @include for-tablet-portrait-up {
      width: 88px;
      height: 40px;
    }
  }

  @include for-tablet-portrait-up {
    label {
      line-height: 22px;
    }
  }

  @include for-tablet-portrait-up {
    & > .form-item {
      margin-bottom: 60px;
    }
  }

  .form-item {
    &.legal {
      flex-direction: row;
      justify-content: flex-start;
      align-items: space-between;

      @include for-tablet-portrait-up {
        justify-content: unset;
        flex-direction: row;
        align-items: center;
        // margin-top and margin-bottom in horizontal-align.legal are same to give same distance between p in between them
        margin: auto 0;
        margin-top: 20px;
      }

      &:first-of-type {
        @include for-tablet-portrait-up {
          margin-bottom: 60px;
        }
      }
    }
  }
  .form-items-container-legal {
    position: relative;

    @include for-tablet-portrait-up {
      flex-direction: row;
      display: flex;
      margin-top: 46px;
      margin-bottom: 77px;
    }

    .form-item {
      &:first-child {
        margin-bottom: 48px;

        @include for-tablet-portrait-up {
          margin: 0;
          margin-right: 138px;
        }
      }

      &:last-child {
        margin-bottom: 0;

        @include for-tablet-portrait-up {
          margin: 0 auto;
        }
      }
    }

    p {
      @include for-tablet-portrait-up {
        padding-right: 28px;
      }
    }
  }
}

.eight {
  .content {
    p {
      @include for-tablet-portrait-up {
        max-width: 98%;
      }

      &:first-of-type {
        @include for-tablet-portrait-up {
          margin-top: 41px;
          margin-bottom: 47px;
        }
      }
    }

    .text-blockend-m {
      @include for-tablet-portrait-up {
        margin-top: 26px;
      }
      p {
        @include for-tablet-portrait-up {
          margin: 0;
        }

        &:first-of-type {
          @include for-tablet-portrait-up {
            margin-bottom: 36px;
          }
        }
      }
    }
  }
}

// SECTION 3.9 - anything else we need to know about this agreement?
.nine {
  .content {
    display: flex;
    flex-direction: column;

    @include for-tablet-portrait-up {
      display: block;
    }
  }
  .infos {
    background: #fcfcfc;
    border: 1px solid rgba(255, 232, 220, 0.8);
    box-sizing: border-box;
    border-radius: 9px 2px 3px 3px;
    margin-top: 10px;
    max-height: 350px;
    position: relative;

    @include for-tablet-portrait-up {
      max-width: 614px;

      &:not(:last-child) {
        margin: 18px auto;
      }

      &:last-child {
        margin: 0 auto;
      }
    }

    .title-bg {
      border-radius: 9px 2px 0px 0px;
      color: #ffffff;
      cursor: pointer;

      &.orange {
        background-color: #ffba97;
      }

      &.pink {
        background-color: #dda6cc;
      }

      &.blue {
        background-color: #1d445f;
      }

      h4 {
        padding: 10px 20px 10px 13px;
        margin: 0;
        font-size: 16px;
        font-weight: 400;

        @include for-iphone678 {
          font-size: 18px;
        }

        @include for-tablet-portrait-up {
          padding: 10px 13px;
          -webkit-user-select: none;
          -moz-user-select: none; /* Firefox */
          -ms-user-select: none; /* IE10+ */
        }
        // triangle
        span {
          width: 0;
          height: 0;
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-bottom: 7px solid white;
          transform: rotate(180deg);
          transition: transform 0.4s ease;
          position: absolute;
          right: 10px;
          top: 15px;
        }
      }
    }

    .text {
      padding: 0 13px;
      max-height: 0;
      overflow: hidden;
      // transition: max-height 0.5s;
      transition: max-height 0.4s cubic-bezier(0, 1, 0, 1);

      p {
        line-height: 20px;
      }
    }

    // when active class is added
    &.active {
      h4 {
        // rotate triangle
        span {
          transform: rotate(0deg);
          transition: transform 0.4s ease;
        }
      }
      // show hidden text
      .text {
        max-height: 300px;
        // transition: max-height 0.5s;
        transition: max-height 0.4s ease-in-out;
      }
    }
  }
}

// SECTION 4 - SIGNATURE
section.sign {
  padding: 14px 20px 18px;

  @include for-tablet-portrait-up {
    padding: 0;
  }

  h2,
  .signature-subtitle {
    @include for-tablet-portrait-up {
      padding-left: 45px;
    }

    @include for-tablet-portrait-up {
      padding-left: 65px;
    }
  }

  h2 {
    @include for-tablet-portrait-up {
      margin-top: 34px;
    }
  }

  .signature-subtitle {
    @include for-tablet-portrait-up {
      width: 733px;
    }
  }

  .wrapper-container {
    @include for-tablet-portrait-up {
      padding: 0;
    }
  }
  .parties-info-wrapper {
    @include for-tablet-portrait-up {
      margin-top: 25px;
    }
  }
  .parties-info {
    @include for-tablet-portrait-up {
      align-items: center;
      padding: 17px 0;
    }

    .circle.large {
      &.mobile {
        left: -82px;
        bottom: -40px;
        height: 103px;
        width: 103px;

        @include for-iphone678 {
          left: -83px;
          bottom: -43px;
        }

        @include for-tablet-portrait-up {
          display: none;
        }
      }

      &.desktop {
        display: none;
        // adjust circle position for ipad and up
        @include for-tablet-portrait-up {
          height: 142px;
          width: 142px;
          left: auto;
          right: -130px;
          bottom: -23px;
          display: flex;

          span {
            margin: auto;
            font-size: 74px;
          }
        }
      }
    }

    .container {
      display: flex;
      flex-direction: column;
      position: relative;

      @include for-tablet-portrait-up {
        padding-left: 100px;
      }
    }

    .party {
      margin-bottom: 30px;

      @include for-tablet-portrait-up {
        position: relative;
        height: 228px;
        justify-content: flex-start;
      }

      @include for-tablet-portrait-up {
        &.part-a {
          margin: auto 0 auto auto;

          &:first-of-type {
            margin-bottom: 17px;
          }
        }

        &.part-b {
          margin: 0 auto auto;
        }
      }

      // add margin-bottom to all form-items except for mobile phone
      .form-item {
        & + .form-item {
          margin-top: 11px;
        }

        &.main-contact.signature {
          &.active {
            margin-bottom: 0px;
          }
        }
      }
    }
    .signature-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 180px;

      @include for-tablet-portrait-up {
        width: 204px;
        height: auto;
        margin-top: 15px;
        flex-direction: row;
      }

      .form-item {
        margin-left: auto;
        margin-bottom: auto;
        margin-top: 15px;
        justify-content: flex-end;

        @include for-tablet-portrait-up {
          justify-content: flex-start;
          margin-top: 0;
          margin: 0;
          margin-left: 0;
          margin-bottom: auto;
          width: auto;
        }

        // duly authorized
        span {
          margin-left: 0;
          font-size: 14px;
          height: 22px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.5);

          @include for-iphone678 {
            font-size: 15px;
          }

          @include for-tablet-portrait-up {
            font-size: 12px;
          }
        }
      }

      .docu-sign {
        padding: 16px 0;
        border: 1px solid black;
        width: 106px;
        display: flex;
        margin-left: auto;
        margin-top: 26px;

        @include for-tablet-portrait-up {
          margin-top: 0;
        }

        span {
          margin: auto;
        }
      }
    }
  }

  .confirm-send-container {
    &.desktop {
      display: none;
      height: 0;
      width: 0;

      @include for-tablet-portrait-up {
        height: 229px;
        width: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        p {
          font-size: 22px;
          margin: 0 auto;
          width: auto;
          margin-bottom: 15px;
        }

        // confirm and send button
        button {
          width: 222px;
          height: 49px;

          span {
            font-size: 22px;
          }
        }
      }
    }

    &.mobile {
      margin-top: 26px;
      margin: 26px auto 0;
      display: flex;
      padding-left: 40px;

      @include for-iphone678 {
        padding-left: 0;
      }

      @include for-tablet-portrait-up {
        margin: 0;
        padding: 0;
        display: none;
      }

      .rounded-edge-button {
        display: flex;
        margin: 0 auto;
        width: auto;
        padding: 10px 30px;

        @include for-iphone678 {
          width: 240px;
        }

        span {
          color: white;
          margin: auto;
          font-size: 22px;
        }
      }
    }
  }
}

// last section
.agreement-section {
  &.active {
    @include for-tablet-portrait-up {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0;
      flex-grow: 1;
    }

    @include for-tablet-landscape-up {
      flex-grow: 1;
    }
  }

  .quote-header {
    img.desktop {
      @include for-desktop-up {
        height: 179px;
        width: 199px;
      }
    }
  }

  .wrapper-container {
    @include for-tablet-portrait-up {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-top: 135px;
      padding-bottom: 45px;
    }

    @include for-tablet-landscape-up {
      flex-grow: 0;
      justify-content: start;
      padding-bottom: 27px;
    }
  }

  .quote-header {
    h2 {
      margin-block-start: unset;

      @include for-tablet-landscape-up {
        margin-block-end: 0.83em;
      }
    }

    img {
      @include for-tablet-portrait-up {
        top: -108px;
      }
    }
  }

  .content-container {
    display: flex;
    flex-direction: column;

    @include for-tablet-portrait-up {
      flex-grow: 1;
    }

    @include for-tablet-landscape-up {
      padding: 0 41px;
      flex-grow: 0;
    }

    @include for-ipad-pro-portrait-up {
      justify-content: center;
      flex-grow: 0;
    }

    &:before {
      @include for-tablet-portrait-up {
        content: "";
        background-image: url("public/Logo_SOPHiA-1.png");
        background-repeat: no-repeat;
        background-size: contain;
        display: block;
        width: 216px;
        height: 70px;
        position: absolute;
        top: 30px;
      }
    }

    p {
      @include for-tablet-portrait-up {
        margin-bottom: 23px;
      }
    }

    li {
      @include for-tablet-portrait-up {
        margin: 0 5px 24px -9px;
      }
    }
  }

  .footer-agreement-container {
    @include for-tablet-portrait-up {
      position: relative;
      z-index: 10;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    @include for-tablet-landscape-up {
      flex-grow: 0;
      margin-top: 35px;
    }

    @include for-ipad-pro-portrait-up {
      margin-top: 72px;
      flex-grow: 0.2;
    }

    @include for-desktop-up {
      margin-top: 35px;
    }

    .home-page {
      height: 0;
      width: 0;
      display: none;

      @include for-tablet-portrait-up {
        display: inline-block;
        width: auto;
        visibility: visible;
        text-decoration: underline;
        color: black;
        font-size: 20px;
        line-height: 24px;
        padding-left: 45px;

        &:hover {
          color: #1d445f;
        }
      }

      @include for-tablet-landscape-up {
        padding-left: 65px;
        margin-bottom: 45px;
      }
    }

    // POPUP
    .popup {
      background: #1d445f;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
      border-radius: 3px;
      color: white;
      padding: 15px;
      font-weight: normal;
      opacity: 1;

      @include for-tablet-portrait-up {
        margin: 30px auto 30px;
        width: 100%;
        // height: 120px;
        display: flex;
        align-items: center;

        span {
          padding-left: 12px;
        }
      }

      @include for-desktop-up {
        margin-top: 30px;
      }

      &.active {
        opacity: 0;
        transition: opacity 10s linear;
      }
    }
  }
}
