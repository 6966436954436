.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background: rgba(184, 184, 184, 0.6);
    opacity: .5;
    transition: opacity 0.7s cubic-bezier(0.22, 0.61, 0.36, 1);
}

.modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;  
    display: flex;
    padding: 0 6.6vw;

    @include for-tablet-portrait-up { 
        padding: 0;
    }

    .modal-container {
        z-index: 100;
        background: white;
        position: relative;
        margin: auto;
        border-radius: 6px;
        width: 100%;
        // transform: translate(0, 100px);
        opacity: 0;
        transition: opacity 0.7s cubic-bezier(0.22, 0.61, 0.36, 1);
        transition: transform 0.7s cubic-bezier(0.22, 0.61, 0.36, 1);

        @include for-tablet-portrait-up {
            max-width: 631px;
        }

    }
    .viewport {
        height: 100%;
        width: 100%;

    }
    .inner {
        height: 100%;
        width: 100%;
        padding: 13px 16px 21px;

        @include for-tablet-portrait-up {
            padding: 34px 24px 23px 48px;
        }

    }
    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        text-align: center;
        height: 100%;

        @include for-tablet-portrait-up {
            text-align: left;
            align-items: flex-start;
        }

    }

    h2 {
        font-weight: bold;
        line-height: 28px;
        margin-bottom: 11px;
        font-size: 22px;

        @include for-tablet-portrait-up {
            font-size: 32px;
            line-height: 44px;
            margin-bottom: 17px;
            margin-top: 0;
        }
    }
    
    p  {
        margin: 0;
        font-size: 12px;
        line-height: 22px;
        color: #B8B8B8;
        margin-bottom: 12px;

        @include for-tablet-portrait-up {
            font-size: 14px;
            line-height: 18px;
            margin-bottom: 13px;
            font-size: 14px;
            line-height: 18px;
        }
    }

    .form-item {
        margin: auto auto  29px;
        width: 180px;
        height: 32px;

        @include for-tablet-portrait-up {
            width: 206px;
            height: 32px;
            margin: 0;
            margin-bottom: 19px;
        }

        input {
            height: 100%;
            width: 100%;

            @include for-tablet-portrait-up {
                margin-right: auto;
            }
        }
    }

    .rounded-edge-button {
        height: 49px;
        font-size: 24px;
        margin-top: auto;
        color: white;

        @include for-tablet-portrait-up {
            margin-left: auto;
            margin-top: unset;
         }
    }

    &.opened {
        .modal-container {
            opacity: 1;
            // transform: translate(0, 0px);
        }
    }
}