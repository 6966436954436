@import '../fonts/GT-America-Standard-Light.ttf';

@font-face {
    font-family: 'GTAmerica';
    src: local('GTAmerica'), url(../fonts/GT-America-Standard-Light.ttf) format('truetype');
}

@font-face {
    font-family: 'OpenSans';
    src: local('OpenSans'), url(../fonts/OpenSans-ExtraBold.ttf) format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans';
    src: local('OpenSans'), url(../fonts/OpenSans-ExtraBoldItalic.ttf) format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'OpenSans';
    src: local('OpenSans'), url(../fonts/OpenSans-Bold.ttf) format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans';
    src: local('OpenSans'), url(../fonts/OpenSans-BoldItalic.ttf) format('truetype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'OpenSans';
    src: local('OpenSans'), url(../fonts/OpenSans-SemiBold.ttf) format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans';
    src: local('OpenSans'), url(../fonts/OpenSans-SemiBoldItalic.ttf) format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'OpenSans';
    src: local('OpenSans'), url(../fonts/OpenSans-Regular.ttf) format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans';
    src: local('OpenSans'), url(../fonts/OpenSans-Italic.ttf) format('truetype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'OpenSans';
    src: local('OpenSans'), url(../fonts/OpenSans-Light.ttf) format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans';
    src: local('OpenSans'), url(../fonts/OpenSans-LightItalic.ttf) format('truetype');
    font-weight: 300;
    font-style: italic;
}
$form_font_style: "GTAmerica";
$body_font_style: "OpenSans";
$header_font_style: "OpenSans";
